.box-result {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 24px;
}

.box-result-mobile {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px 12px;
  margin-bottom: 16px;
}


.btn-box-time {
  border-radius: 4px;
  padding: 8px 16px;
  width: 81px;
  text-align: center;
  height: 42px;
  background: #d0d5dd;
  border-radius: 4px;
  margin-right: 24px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  transition: all linear 0.3s;
}

.btn-box-time:hover {
  color: white !important;
  background: linear-gradient(95.65deg, #7932ed 0.99%, #ed3275 100%);
}

.hover-address {
  cursor: pointer;
  position: relative;
  transition: all linear 0.5s;
}

.hover-address:hover .mapouter {
  display: block;
}

.hover-address:hover .hover-address .ant-typography {
  color: #7932ed !important;
}
.mapouter {
  position: absolute;
  text-align: right;
  width: 600px;
  height: 400px;
  position: absolute;
  top: 25px;
  z-index: 1000;
  display: none;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 600px;
  height: 400px;
}
.gmap_iframe {
  width: 600px !important;
  height: 400px !important;
}

.btn-box-time-mobile {
  border-radius: 4px;
  padding: 8px 16px;
  width: 73.76px;
  height: 36px;
  text-align: center;
  background: #d0d5dd;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: all linear 0.3s;
}

.btn-box-time-mobile:hover {
  color: white !important;
  background: linear-gradient(95.65deg, #7932ed 0.99%, #ed3275 100%);
}

.text-info-mobie {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 7px;
}
.text-link-mobile{
  text-decoration: underline !important;
}

.box-wrapper-order{
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  background-color: #F5F5F5;
  border-radius: 8px;
  padding: 20px;
}

.text-order-price{
	margin: 0px;
	font-size: 16px;
	font-weight: 500;
}

.text-order-number{
	font-size: 22px;
	font-weight: 700;
	margin: 0px;
}

.hint-discount {
  margin-left: 4px;
  background: #ccc;
  padding: 0px 7px;
  border-radius: 50%;
  cursor: pointer;
}

.text-order-number .origin-price.not-same {
  margin-right: 8px;
  text-decoration: line-through;
  color: #ccc;
}

.color-btn{
  color: rgba(121, 50, 237, 1) !important;
}


.btn-search-mobile {
  background: var(
    --pimary-btn-bg,
    linear-gradient(96deg, #7932ed 0.99%, #ed3275 100%)
  );
  border-radius: 8px;
  color: white !important;
  height: 50px;
	width: 100%;
  font-weight: 500;
  font-size: 16px;
  transition: all linear 0.3s;
}

.btn-search-mobile:hover {
  color: #7932ed !important;
}