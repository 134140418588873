.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 8%;
	background-color: white;
}

.wrapper-header-icon-laptop {
	display: flex;
	align-items: center;
	gap: 30px;
}
.wrapper-header-icon-mobile {
	display: none;
	gap: 30px;
}
.logo-2 {
	display: none;
}

.logo-1 {
	cursor: pointer;
}

.footer {
	background-color: #f9fafb;
	padding-top: 30px;
	padding-bottom: 30px;
}
@media only screen and (max-width: 768px) {
	.wrapper-header-icon-laptop {
		display: none;
	}
	.wrapper-header-icon-mobile {
		display: flex;
	}
	.logo-1 {
		display: none;
	}
	.logo-2 {
		display: flex;
		justify-content: start;
	}
	.header {
		padding: 10px 20px;
	}
}
