.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.p-2 {
  padding: 20px;
}

.p-1 {
  padding: 10px;
}

.m-2 {
  margin: 20px;
}

.m-1 {
  margin: 10px;
}

.text-white {
  color: white;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.m-0 {
  margin: 0 !important;
}

.p-3 {
  padding: 30px !important;
}

.pe-2 {
  padding-right: 20px !important;
}

.ps-2 {
  padding-left: 20px !important;
}

.pe-1 {
  padding-right: 10px !important;
}
.ps-1 {
  padding-left: 10px !important;
}

.justify-content-center {
  justify-content: center;
}
.text-red {
  color: red;
}

.ms-1 {
  margin-left: 10px !important;
}

.me-1 {
  margin-right: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.text-center {
  text-align: center !important;
}

.image-icon {
  width: 24px !important;
  height: 24px !important;
  padding: 2px;
  margin-right: 5px;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.ant-modal-content {
  padding: 10px !important;
}

.input-code .ant-input-number-input {
  text-align: center !important;
  width: 81px !important;
}

.input-code {
  width: 81px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
}

.text-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.mt-1 {
  margin-top: 10px;
}

.text-info {
  font-size: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  margin-left: 5px;
  color: #333333;
}
.mt-0 {
  margin-top: 0px !important;
}

.px-3 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.text-time {
  font-size: 16px;
  font-weight: 500;
}

.fw-500 {
  font-weight: 500 !important;
}

.ms-0 {
  margin-left: 0px;
}

/* @media only screen and (max-width: 770px) { */
  .borderTime {
    border-bottom: 1px dashed #d0d5dd;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
/* } */

.border-ourline {
  border-top: 1px dashed #d0d5dd;
}
.bg-image {
  background-image: url("./assets/images/bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.bg-image-mobile {
  background-image: url("./assets/images/bg.png");
  background-size: cover;
  background-position: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.bg-search-order {
  width: 90vw;
  background-color: white;
  overflow: auto;
  height: 90vh;
}

.text-style-lean {
  font-style: italic;
  color: #6a6a6a;
}
.border-bottom-outline {
  border-bottom: 1px dashed #d0d5dd;
  padding-bottom: 20px;
}

.bg-wrapper {
  height: 100vh;
  overflow: hidden;
}

.wrapper-right {
  height: 100vh;
  overflow: hidden;
}

.px-8 {
  padding-left: 80px;
  padding-right: 80px;
}

.mx-8 {
  margin-left: 80px;
  margin-right: 80px;
}
.ms-8 {
  margin-left: 80px;
}

.pe-8 {
  padding-right: 80px;
}

.mt-4 {
  margin-top: 40px;
}

body {
  font-family: "Inter";
}

.order-right {
  overflow: auto;
  position: relative;
}

.order-right::-webkit-scrollbar {
  width: 5px !important;
  background-color: rgb(234, 231, 231);
  height: 50px;
}

.order-right::-webkit-scrollbar-thumb {
  background: #888;
}
.order-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle on hover */
.order-right::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.wrapper-right {
  display: flex;
  flex-direction: column;
}

.box-time {
  flex-wrap: wrap;
}

.wrapper-mobile {
}

.wrapper-mobile-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: white;
}

.order-right-mobile {
  padding-left: 16px;
  padding-right: 16px;
  /* padding-top: 230px; */
  padding-bottom: 24px;
}

.order-right-mobile::-webkit-scrollbar {
  width: 5px !important;
  background-color: rgb(234, 231, 231);
  height: 50px;
}

.order-right-mobile::-webkit-scrollbar-thumb {
  background: #888;
}
.order-right-mobile::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle on hover */
.order-right-mobile::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.text-header-mobile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px !important;
}
.fs-1rem {
  font-size: 1rem !important;
}

.fw-400 {
  font-weight: 400;
}

.form-mobile-branch {
  margin-top: 10px;
  margin-bottom: 24px;
  padding-right: 12px;
}

.form-mobile-branch-1 {
  margin-top: 10px;
  margin-bottom: 24px;
  padding-left: 12px;
}

.padding-time-right {
  padding-left: 12px;
}
.padding-time-left {
  padding-right: 12px;
}

.ant-modal-wrap {
  z-index: 10000 !important;
}
.ant-modal-mask {
  z-index: 10000 !important;
}

.wrapper-search-mobile {
  display: none !important;
  margin-top: 1px;
}

@media only screen and (max-width: 1224px) {
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps
    .ant-steps-item-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1224px) {
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps
    .ant-steps-item-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
  }
}

.text-header-search {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px 0 !important;
  margin: 0px;
  margin-bottom: 0px !important;
  z-index: 1000;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  :where(.css-12jzuas).ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bg-search-order {
    width: 90vw;
    background-color: white;
    overflow: auto;
    padding-bottom: 20px;
    height: 90vh;
    border-radius: 4px;
  }

  .wrapper-search-laptop {
    display: none !important;
  }

  .wrapper-search-mobile {
    display: block !important;
  }

  .text-header-search {
    font-size: 22px !important;
    font-weight: 600;
  }

  .text-header-search-content {
    font-size: 12px !important;
  }

  .ant-steps.ant-steps-vertical {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container {
    display: flex;
    align-items: center;
  }
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content {
    display: flex;
    align-items: center;
  }
  .box-time-info-mobile {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px 12px !important;
  }
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    height: 0;
  }
  .form-mobile-branch-1 {
    padding-left: 0 !important;
  }

  .form-mobile-branch {
    padding-right: 0 !important;
  }

  .padding-time-right {
    padding-left: 10px !important;
  }
  .padding-time-left {
    padding-right: 10px !important;
  }
}


@media only screen and (max-width: 540px) {
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  :where(.css-12jzuas).ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bg-search-order {
    width: 90vw;
    background-color: white;
    overflow: auto;
    padding-bottom: 20px;
    height: 90vh;
    border-radius: 4px;
  }

  .wrapper-search-laptop {
    display: none !important;
  }

  .wrapper-search-mobile {
    display: block !important;
  }

  .text-header-search {
    font-size: 22px !important;
    font-weight: 600;
  }

  .text-header-search-content {
    font-size: 12px !important;
  }

  .ant-steps.ant-steps-vertical {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container {
    display: flex;
    align-items: center;
  }
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content {
    display: flex;
    align-items: center;
  }
  .box-time-info-mobile {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px 12px !important;
  }
  :where(.css-dev-only-do-not-override-15rg2km).ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    height: 0;
  }
  .form-mobile-branch-1 {
    margin-top: 0 !important;
    padding-left: 0 !important;
  }

  .form-mobile-branch {
    padding-right: 0 !important;
  }

  .padding-time-right {
    padding-left: 0px !important;
    margin-top: 24px;
  }
  .padding-time-left {
    padding-right: 0px !important;
  }
}

.top-line-dashed {
  border-top: 1px dashed #d0d5dd;;
}

.bottom-line {
  border-bottom: 1px dashed #d0d5dd;;
}

.w-full {
  width: 100%;
}
