.bg-color {
	background-color: #f5f5f5;
}

.blog-main {
	margin: 20px 8%;
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
}

.text-header {
	color: #6a6a6a;
	font-size: 16px;
	font-weight: 400;
}

.text-blog {
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	text-transform: uppercase;
}

.text-header-content {
	text-align: center;
	color: #6a6a6a;
	font-size: 16px;
	text-transform: uppercase;
}

.text-title-content {
	font-weight: bold;
	font-size: 24px;
	color: #333333;
	text-align: center;
	margin: 10px 0;
}

.text-time-content {
	text-align: center;
	color: #6a6a6a;
	font-size: 14px;
}

.margin-wrapper-blog {
	margin: 20px 8%;
}

.text-blog-content {
	margin-top: 20px;
	font-size: 16px;
	font-weight: 400;
	color: #333333;
}
@media only screen and (max-width: 768px) {
	.margin-wrapper-blog {
		margin: 20px;
	}
	.blog-main {
		margin: 20px;
	}
}
