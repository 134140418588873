.time-render{
    color: #ED3237;
    font-size: 24px;
    font-weight: 700;
}

.box-time-info{
    background-color: #F9FAFB;
    border-radius: 8px;
    padding: 16px 24px;
}

