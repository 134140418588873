.box-music-wrapper {
  padding: 40px;
  width: 60%;
  margin: 0 auto;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.4);
}

.box-music-wrapper-mobile{
  padding: 20px;
  margin: 20px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.4);
}
.img-logo {
  margin-bottom: 30px;
}

.text-content {
  color: white;
  font-style: normal;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: justify;
}

.box-content-order {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  text-transform: uppercase;
  margin: 20px 0;
}

.box-content-icon {
  margin-right: 10px;
}
.box-content-text {
  color: white;
}

.btn-cancel-order {
  color: #7932ed;
  padding: 3px 12px;
  height: 38px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  border-radius: 8px;
  border: 1px solid #7932ed;
}

.btn-pay-qr-order {
  background-color: #7932ed;
  color: white;
  padding: 3px 12px;
  height: 38px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  border-radius: 8px;
  border: 1px solid #7932ed;
}

.btn-search-order {
  color: #7932ed;
  padding: 12px 24px;
  height: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  border-radius: 8px;
  border: 1px solid #7932ed;
}

.btn-search-order-mobile {
  color: white;
  padding: 8px 16px;
  height: 36px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background-color: transparent ;
  border-radius: 8px;
  line-height: 20px;
  border: 1px solid white;
}

.title-mobile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 24px;
}

.wrapper-box-steps-mobile {
  background: #f9fafb;
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 14px;
}

.wrapper-box-steps {
  background-color: #f9fafb;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.btn-search {
  border-radius: 8px;
  background: var(
    --pimary-btn-bg,
    linear-gradient(96deg, #7932ed 0.99%, #ed3275 100%)
  );
  border-radius: 8px;
  color: white;
  width: 200px;
  height: 50px;
  font-weight: 500;
  font-size: 18px;
  transition: all linear 0.3s;
}

.select-time-line .ant-select-selector {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.select-option-time-line .ant-select-selector {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-back {
  border-radius: 8px;
  width: 200px;
  height: 50px;
  font-size: 16px;
  color: #7932ed;
}
.close-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: white;
  border-radius: 50%;
  cursor: pointer;
}

.text-order-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 36px !important;
  margin-left: 12px !important;
}

.flex-1 {
  flex: 1 !important;
}

.bg-header-mobile{
  background-color: rgba(0, 0, 0, 0.6);
  padding: 16px;
}