.main-home {
	padding-bottom: 30px;
}

.text-muzic {
	display: flex;
	align-items: center;
	color: #6a6a6a;
	font-size: 16px;
	font-weight: 600;
}

.text-span {
	height: 2px;
	width: 24px;
	background-color: #6a6a6a;
	margin-left: 10px;
}

.text-banner-top {
	font-size: 48px;
	font-weight: 700;
	color: #7932ed;
	text-transform: uppercase;
}

.text-banner-bottom {
	font-size: 48px;
	font-weight: 700;
	background: var(--pimary-btn-bg,
			linear-gradient(20deg, #7932ed 1%, #ed3275 100%));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.slide-banner .slick-dots-bottom {
	margin-top: 10px;
	z-index: 1000;
	bottom: -20px !important;
}

.slide-banner .slick-dots-bottom li {
	background-color: #6a6a6a;
	border-radius: 100%;
	width: 12px !important;
	height: 12px !important;
	z-index: 1000;
}

.slide-banner .slick-dots-bottom .slick-active {
	background: var(--pimary-btn-bg,
			linear-gradient(96deg, #7932ed 5%, #ed3275 100%));
	width: 64px !important;
	height: 12px !important;
	border-radius: 12px;
}

.slide-banner-item {
	display: flex !important;
	justify-content: center;
}

.banner {
	background-image: url("../images/background1.png");
	background-size: cover;
	background-position: center;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 20px 8%;
}

.home-for-you {
	background-image: url("../images/background2.png");
	background-size: cover;
	background-position: center;
	padding: 20px 8%;
}

.card-item {
	cursor: pointer;
	transition: all linear 0.35s;
	border-radius: 8px;
}

.card-item:hover {
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
	transform: translate(0, -10px);
}

.card-item .ant-card-body {
	padding: 0px !important;
	border-radius: 8px;
	height: 100% !important;
}

.wrapper-box-content {
	padding: 20px;
}

.card-item-li {
	font-size: 16px;
	font-weight: 400;
	color: #6a6a6a;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 21px;
	max-height: 48px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.card-text-content {
	font-size: 1em;
	font-weight: 600;
	color: #333333;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 21px;
	max-height: 48px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.border-item {
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	height: 100%;
}

.card-md {
	display: none !important;
}

.margin-wrapper {
	padding: 20px 8%;
}

.slick-next:before,
.slick-prev:before {
	color: #6a6a6a !important;
	font-size: 22px;
}

.wrapper-image-room {
	position: relative;
}

.icon-room {
	position: absolute;
	bottom: -24px;
	left: 20px;
	background-color: white;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
}

.text-name {
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 10px;
	height: 40px;
}

.text-address {
	font-size: 16px;
	font-weight: 400;
	color: #333333;
}

.btn-order-home {
	font-size: 18px;
	font-weight: 500;
	border: 1px solid #7932ed;
	color: #7932ed;
	height: 48px;
	display: flex;
	align-items: center;
	border-radius: 8px;
	gap: 10px;
	margin-top: 10px;
}

.items-address {
	display: flex;
	height: 50px;
}

.icon-phone-home {
	width: 24px !important;
}

@media only screen and (max-width: 768px) {
	.banner {
		padding: 20px;
	}

	.text-banner-top {
		font-size: 26px;
	}

	.text-banner-bottom {
		font-size: 26px;
	}

	.btn-responsive {
		width: 100%;
	}

	.home-for-you {
		padding: 20px;
	}

	.margin-wrapper {
		padding: 20px;
	}

	.text-name {
		font-size: 14px !important;
		font-weight: 600;
		line-height: 20px;
	}

	.icon-address {
		display: none;
	}
	.icon-phone-home {
		display: none;
	}

	.text-address {
		font-size: 14px;
		font-weight: 400;
	}

	.btn-order-home {
		font-size: 14px;
		width: 100%;
	}

	.wrapper-box-content {
		padding: 30px 10px 20px 10px;
	}
}

@media only screen and (max-width: 1200px) {
	.card-md {
		display: flex !important;
	}
}

.wrapper-image-room-inner {
	max-height: 15vw;
	overflow: hidden;
}

@media (max-width: 1200px) {
	.wrapper-image-room-inner {
		max-height: 20vw;
		overflow: hidden;
	}
}

@media (max-width: 992px) {
	.wrapper-image-room-inner {
		max-height: 30vw;
		overflow: hidden;
	}
}

@media (max-width: 768px) {
	.wrapper-image-room-inner {
		max-height: 34vw;
		overflow: hidden;
	}
}

@media (max-width: 576px) {
	.wrapper-image-room-inner {
		max-height: 34vw;
		overflow: hidden;
	}
}
