.icon-position-wrapper {
  position: relative;
}
.icon-position-item {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.box-wrapper {
  padding: 24px;
  background: #f9fafb;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.box-wrapper-mobile {
  padding: 16px 12px;
  background: #f9fafb;
  border-radius: 8px;
}

.text-remove-order {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.btn-close-cancle {
  padding: 12px 24px;
  width: 151px;
  height: 50px;
  border-radius: 8px;
  margin-right: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: rgba(121, 50, 237, 1);
  border: 1px solid rgba(121, 50, 237, 1);
}

.btn-close-success {
  padding: 12px 24px;
  width: 151px;
  height: 50px;
  background: linear-gradient(95.65deg, #7932ed 0.99%, #ed3275 100%);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.text-content-result {
  font-family: "Inter";
  color: #6a6a6a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 0;
}

.text-header-infor {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #333333 !important;
  margin-bottom: 24px;
  display: block;
}
